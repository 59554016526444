<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <a-card>
      <template slot="title">
        <strong>Neraca</strong>
        <a-date-picker
          class="ml-4"
          style="width: 150px;"
          v-model="tgl1"
          :allowClear="false"
          format="DD-MM-YYYY"
          @change="filterDate"
        />
            <a-button
              class="btn btn-outline-success ml-2"
              @click="getAllData(true)"
              >Refresh Data</a-button
            >
      </template>
        <ag-grid-vue
          style="height: 68vh;"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :key="componentKey"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :pagination="allowPagination"
          :paginationPageSize="20"
          :cacheQuickFilter="true"
          :quickFilterText="tablequickfilter"
          :accentedSort="false"
          :sortingOrder="['asc', 'desc']"
          :rowData="rowData"
          @grid-ready="onGridReady"
          :singleClickEdit="false"
          @cell-value-changed="onCellValueChanged"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="5"
          @first-data-rendered="onFirstDataRendered"
          rowSelection="single"
          @selection-changed="onSelectionChanged"
          :rowClassRules="rowClassRules"
        >
        </ag-grid-vue>
    </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'

export default {
  computed: mapState(['settings', 'user']),
  created() {
    this.gridOptions = {}
    this.getAllData()
  },
  data() {
    return {
      tgl1: moment(),
      componentKey: 0,
      // Property ag-grid
      mode: 'netral',
      theme: this.$store.state.settings.theme,
      gridOptions: null,
      selectedData: null,
      gridApi: null,
      backupRowData: [],
      changedData: [],
      rowData: [],
      tablequickfilter: '',
      allowPagination: false,
      columnDefs: [
        {
          headerName: 'Kode',
          field: 'kode',
          editable: false,
          filter: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Saldo',
          field: 'saldo',
          editable: false,
          filter: true,
          resizable: true,
          sortable: true,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        {
          headerName: 'Jenis',
          field: 'jenis',
          editable: false,
          filter: true,
          resizable: true,
          sortable: true,
          valueFormatter: this.changeJenis,
        },
        // {
        //   headerName: 'Keterangan',
        //   field: 'keterangan',
        //   editable: true,
        //   resizable: true,
        //   sortable: true,
        // },
      ],
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          // console.log('params.data.jenis', params.data.jenis)
          return params.data.jenis === 'I'
        },
      },
      // End property

      // Modal Property
      formvisible: false,
      carinasabahtitle: 'Cari',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
    }
  },
  methods: {
    moment,
    filterDate(date, dateString) {
      this.tgl1 = date
      this.getAllData()
    },
    async getAllData(onclick = false) {
      // console.log('this.allMaster', this.allMaster)
      var imbuhan =
        this.tgl1 !== null && this.tgl1 !== ''
          ? '?tgl=' + this.tgl1.format('YYYY-MM-DD')
          : ''
      var response = await lou.customUrlGet2(
        'report/akuntansi/neraca' + imbuhan, false, true,
      )
      if (response) {
        this.rowData = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      // console.log('this.data', this.data)
    },
    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    formatTanggal(v) {
      // console.log('v', v)
      // console.log('ntgl', ntgl)
      return moment(v, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    changeJenis(v) {
      console.log('v', v)
      var ret = v.value === 'I' ? 'Induk' : 'Detail'
      return ret
    },
    currencyFormatter(params) {
      // console.log('params', params)
      var ret = params.value === null ? 'NaN' : lou.curency(params.value)
      return ret === 'NaN' ? '' : ret
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
